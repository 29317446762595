import { SeoModel } from "@/store/model";

export interface categoryModel {
  _id?: string;
  name: string;
  title_h1?: string;
  parent: string | null;
  short_desc: string | null;
  status: string;
  slug: string;
  type: string;
  seo: SeoModel;
  frontend: boolean;
  children:
    | {
        _id: string;
        name: string;
        slug: string;
        title: string;
      }[]
    | null;
}

export const CategoryDefault: categoryModel = {
  seo: {
    robot: "",
    title: "",
    keywords: "",
    description: "",
  },
  _id: "",
  name: "",
  type: "news",
  short_desc: "",
  parent: "",
  status: "active",
  frontend: true,
  slug: "",
  children: [],
};
